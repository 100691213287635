import { IPrice, IPriceDecorated } from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

import { IDualAreaResults, IGQLPageVariables, IPageGQLResults } from './api-graphql'

export type IPriceGQLResults = IPageGQLResults<IPrice[]>
export type IPriceDecoratedGQLResults = IPageGQLResults<IPriceDecorated[]>
export type IPriceDualAreaGQLResults = IDualAreaResults<IPriceGQLResults>
export type IPriceDecoratedDualAreaGQLResults =
  IDualAreaResults<IPriceDecoratedGQLResults>

export type IPriceResultsVariables = IGQLPageVariables
export const getPriceOnsiteResults = async (
  variables: IPriceResultsVariables = {}
): Promise<IPriceGQLResults> => {
  const query = `
    query PriceOnsiteResults (
      $itemsPerPage: Int = 12
      $company: String = "connect"
    ) {
      prices: collectionPageQueryOnsitePrices (
        itemsPerPage: $itemsPerPage
        company: $company
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    prices: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data.prices
}

export const getPriceOnlineResults = async (
  variables: IPriceResultsVariables = {}
): Promise<IPriceGQLResults> => {
  const query = `
    query PriceOnlineResults (
      $itemsPerPage: Int = 12
      $company: String = "connect"
    ) {
      prices: collectionPageQueryOnlinePrices (
        itemsPerPage: $itemsPerPage
        company: $company
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
          allowTrial
          subscriptionBillingPeriod
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    prices: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data.prices
}

export const getPricePrepaidResults = async (
  variables: IGQLPageVariables = {}
): Promise<IPriceGQLResults> => {
  const query = `
    query PricePrepaidResults (
      $itemsPerPage: Int = 12
      $company: String = "connect"
    ) {
      prices: collectionPageQueryPrepaidPrices (
        itemsPerPage: $itemsPerPage
        company: $company
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    prices: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data.prices
}

export interface IPriceLessonResultsVariables extends IPriceResultsVariables {
  lessonUuid: string
}
export const getPriceLessonResults = async (
  variables: IPriceLessonResultsVariables
): Promise<IPriceGQLResults> => {
  const query = `
    query PriceLessonResults (
      $itemsPerPage: Int = 12
      $lessonUuid: String!
    ) {
      prices: collectionPageQueryPrices (
        itemsPerPage: $itemsPerPage
        lessonUuid: $lessonUuid
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
          allowTrial
          subscriptionBillingPeriod
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    prices: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data.prices
}

export interface IPriceResultVariables {
  priceUuid: string
  lessonUuid?: string
}
export const getPriceResult = async (
  variables: IPriceResultVariables
): Promise<IPrice> => {
  const query = `
    query PriceResult (
      $priceUuid: String!
      $lessonUuid: String
    ) {
      prices: collectionPageQueryHiddenInclPrices (
        uuid: $priceUuid
        lessonUuid: $lessonUuid
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
          allowTrial
          subscriptionBillingPeriod
          prepaid
          hidden
          displayBalance
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    prices: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data.prices.collection[0] ?? null
}

export const getPriceDualAreaResults = async (
  variables: IGQLPageVariables = {}
): Promise<IPriceDualAreaGQLResults> => {
  const query = `
    query PriceDualAreaResults (
      $itemsPerPage: Int = 12
      $company: String = "connect"
    ) {
      onsite: collectionPageQueryOnsitePrices (
        itemsPerPage: $itemsPerPage
        company: $company
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
        }
      }

      online: collectionPageQueryOnlinePrices (
        itemsPerPage: $itemsPerPage
        company: $company
        platform: "web"
      ) {
        collection {
          ${MINIMAL_PRICE_FIELDS}
          allowTrial
          subscriptionBillingPeriod
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    onsite: IPriceGQLResults
    online: IPriceGQLResults
  }>(query, variables)

  return graphqlResult.data
}

const MINIMAL_PRICE_FIELDS = `
  id
  ulid
  name
  areas
  type
  websiteRender
  totalAmount
  taxAmount
  amountUnit
  nbMonthsValidity
  eligible
  eligibilityCondition
  subscription
  recurringSubscription
  asyncPayment
  subscriptionTrialDays
  quantity
  render
  business
`
